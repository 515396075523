import React from 'react';
import { Layout, SEO } from '../layout';
import WithTransition from '../components/WithTransition';
import ImageProp from '../components/ImageProp';
import { StaticImage } from 'gatsby-plugin-image';

const ContactPage = () => (
	<Layout>
		<SEO title="Contato" />
		<section className="section-padding">
			<div className="container flow">
				<h1 className="contact_title"></h1>
				<div className="continer_contact">
					<div className="list_contacts">
						<li className="contact">
							<a href="https://youtube.com/channel/UCQFC7yAk9SogTW-qyFEps3Q">
								<p className="contact_title">Youtube</p>
							</a>
						</li>
						<li className="contact">
							<a href="https://www.instagram.com/pi_mire/">
								<p className="contact_title">Instagram</p>
							</a>
						</li>
						<li className="contact">
							<a href="">
								<p className="contact_title">mirepi.corpo@gmail.com</p>
							</a>
						</li>
						<li className="contact">
							<a href="">
								<p className="contact_title">+55 82 996986996</p>
							</a>
						</li>
					</div>
					<br></br>
					<div className='frame' >
						<StaticImage src="../images/foto_contato.jpeg"
						alt="imagem de fundo azul e mirepi performando" />
						</div>
					
				</div>
			</div>
		</section>
	</Layout>
);

export default WithTransition(ContactPage);
